import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "src/layout/layout"
import SEO from "src/components/seo"
import Hero from "src/components/hero/background-color"
import CTA from "src/components/cta"
import Spacer from "src/components/spacer"
import PageWrapper from "src/components/page-wrapper"
import { Typography } from "@material-ui/core"
import brandLogo from "src/images/dp_aerogels_logo.png"
import brandElement from "src/images/brand_leaf_element.png"
import slogan from "src/images/slogan@4x.png"
import content from "src/locales/en/about-brand-identity.json"

const useStyles = makeStyles({
  root: {
    width: "85%",
    margin: "0 auto",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "120px",
  },
  header: {
    width: "70%",
    margin: "48px",
    "@media (max-width:768px)": {
      width: "90%",
    },
  },
  leftRowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "36px",
    "@media (max-width:768px)": {
      flexDirection: "column-reverse",
    },
  },
  rightRowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "36px",
    "@media (max-width:768px)": {
      flexDirection: "column",
    },
  },
  nestedColumnContainer: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    "@media (max-width:768px)": {
      width: "100%",
      marginTop: "24px",
    },
  },
  rightCanvasCircleBackground: {
    display: "flex",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "48px",
    alignSelf: "center",
    height: "180px",
    width: "180px",
    borderRadius: "50%",
    boxShadow: "-1px 1px 10px 5px rgba(0,0,0,0.1)",
    "@media (max-width:768px)": {
      alignSelf: "flex-start",
      margin: 0,
    },
  },
  canvasCircleBackground: {
    display: "flex",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "24px",
    alignSelf: "center",
    height: "180px",
    width: "180px",
    borderRadius: "50%",
    boxShadow: "-1px 1px 10px 5px rgba(0,0,0,0.1)",
    "@media (max-width:768px)": {
      alignSelf: "flex-start",
      margin: 0,
    },
  },
  centerRowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "36px",
    "@media (max-width:768px)": {
      flexDirection: "column",
    },
  },
  nestedCenterColumnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "60%",
    "@media (max-width:768px)": {
      width: "100%",
      marginTop: "24px",
    },
  },
  coloursContainer: {
    display: "flex",
    width: "80%",
    height: "120px",
    borderRadius: "16px",
    marginTop: "36px",
    "@media (max-width:768px)": {
      width: "100%",
      height: "80px",
    },
  },
  coloursText: {
    color: "#fff",
    paddingLeft: "16px",
    paddingTop: "8px",
    "@media (max-width:768px)": {
      fontSize: "0.875rem",
    },
  },
})

export default function BrandIdentity({ pageContext, location }) {
  const classes = useStyles()

  const {
    breadcrumb: { crumbs },
  } = pageContext
  const customCrumbLabel = crumbs[crumbs.length - 1].crumbLabel.replace(
    "-",
    " "
  )
  return (
    <>
      <Layout location={location}>
      <SEO title={content.seo.title} />
        <Hero
          textTitle={content.hero.heading}
          textDescription={content.hero.description}
          fullHeight={true}
        />
        <PageWrapper>
          <div className={classes.root}>
            <Breadcrumb crumbs={crumbs} crumbLabel={customCrumbLabel} />

            <div className={classes.leftRowContainer}>
              <div className={classes.nestedColumnContainer}>
                <Typography variant="h2">
                  {content.sectionLogo.heading}
                </Typography>
                <Typography variant="body1" style={{ marginTop: "24px" }}>
                  {content.sectionLogo.description}
                </Typography>
              </div>
              <div className={classes.canvasCircleBackground}>
                <img
                  src={brandLogo}
                  alt={"DP Aerogels brand logo"}
                  style={{ width: "100px" }}
                />
              </div>
            </div>

            <Spacer />
            <Spacer />

            <div className={classes.rightRowContainer}>
              <div className={classes.rightCanvasCircleBackground}>
                <img src={brandElement} style={{ width: "100px" }} />
              </div>
              <div className={classes.nestedColumnContainer}>
                <Typography variant="h2">
                  {content.sectionElement.heading}
                </Typography>
                <Typography variant="body1" style={{ marginTop: "24px" }}>
                  {content.sectionElement.description}
                </Typography>
              </div>
            </div>

            <Spacer />
            <Spacer />
          </div>
        </PageWrapper>

        <div
          style={{
            backgroundColor: "#D5DCDD55",
            boxShadow: "-1px 1px 5px 1px #99CC33",
            paddingTop: "36px",
            paddingBottom: "100px",
          }}
        >
          <div
            style={{
              maxWidth: "1440px",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
          >
            <div className={classes.root}>
              <div className={classes.centerRowContainer}>
                <div className={classes.nestedCenterColumnContainer}>
                  <Typography variant="h2">
                    {content.sectionColours.heading}
                  </Typography>
                  <Typography variant="body1" style={{ marginTop: "24px" }}>
                    {content.sectionColours.description}
                  </Typography>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className={classes.coloursContainer}>
                  <div
                    style={{
                      width: "33.33%",
                      backgroundColor: "#2E5254",
                      borderTopLeftRadius: "16px",
                      borderBottomLeftRadius: "16px",
                    }}
                  >
                    <Typography variant="body1" className={classes.coloursText}>
                      dp•Jungle Green
                    </Typography>
                  </div>
                  <div style={{ width: "33.33%", backgroundColor: "#99CC33" }}>
                    <Typography variant="body1" className={classes.coloursText}>
                      dp•Organic Green
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: "33.33%",
                      backgroundColor: "#006E93",
                      borderTopRightRadius: "16px",
                      borderBottomRightRadius: "16px",
                    }}
                  >
                    <Typography variant="body1" className={classes.coloursText}>
                      dp•Silica Blue
                    </Typography>
                  </div>
                </div>
              </div>

              <Spacer />
              <Spacer />

              <div className={classes.centerRowContainer}>
                <div className={classes.nestedCenterColumnContainer}>
                  <Typography variant="h2">
                    {content.sectionMotto.heading}
                  </Typography>
                  <Typography variant="body1" style={{ marginTop: "24px" }}>
                    {content.sectionMotto.description}
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "80px",
                  marginTop: "24px",
                }}
              >
                <img
                  src={slogan}
                  alt="DP Aerogels motto"
                  style={{
                    height: "auto",
                    maxWidth: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <CTA />
      </Layout>
    </>
  )
}
